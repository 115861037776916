<script module lang="ts">
  function debounce(fn: Function) {
    let raf: number | undefined;

    return (...args: any[]) => {
      if (raf) {
        //logger("debounced");
        return;
      }

      raf = requestAnimationFrame(() => {
        fn(...args); // run useful code
        raf = undefined;
      });
    };
  }
</script>

<script>
  import { onMount, type Snippet } from "svelte";

  //import resize from "svelte-use-resize-observer";
  import { resize } from "svelte-resize-observer-action";

  import QRScanner from "qr-scanner";
  import type {
    ChangeEventHandler,
    EventHandler,
    MouseEventHandler,
  } from "svelte/elements";

  //const dispatch = createEventDispatcher();

  //const qrengine = QRScanner.createQrEngine();

  //logger("engine=", qrengine);

  // Component API
  // export function scrollIntoView(opts) {
  //   figureEl?.scrollIntoView(opts);
  // }

  let {
    class: className = null,
    cover = null,
    upload = true,
    barcode = false,
    children,
    onbarcode = null,
    onframe = null,
  } = $props<{
    className?: string | null;
    cover?: string | null;
    upload?: boolean;
    barcode?: boolean;
    children?: Snippet;
    onbarcode?: (barcode: string, frame?: Blob | null) => void;
    onframe?: (frame: Blob) => void;
  }>();

  // Params
  //let mode;
  // export { mode as class };
  // export let cover = null;
  // export let upload = true;
  // export let barcode = false;

  // Element Bindings
  let figureEl: HTMLElement;
  let videoEl: HTMLVideoElement;
  let qrscanner: QRScanner | null = $state(null);
  let loading = $state(true);
  let fallback = $state(false);
  let torch = $state({
    enabled: false,
    active: false,
  });

  $effect(() => {
    if (videoEl && !qrscanner)
      qrscanner = new QRScanner(videoEl, onresult, {
        returnDetailedScanResult: true,
        maxScansPerSecond: barcode ? undefined : 0,
        onDecodeError: function () {},
        // calculateScanRegion: ($video) => ({
        //   x: 0,
        //   y: 0,
        //   width: $video.videoWidth,
        //   height: $video.videoHeight,
        //   downScaledWidth: $video.videoWidth,
        //   downScaledHeight: $video.videoHeight,
        // }),
        //highlightScanRegion: true,
        //highlightCodeOutline: true,
      });
    if (qrscanner) startStream();
  });

  function onresult(result: QRScanner.ScanResult) {
    //logger("decoded qr code:", result);
    onbarcode(result.data, null);
  }

  function startStream() {
    if (fallback || !barcode) return;
    //logger("qrscanner=", qrscanner);
    qrscanner
      ?.start()
      .then(() => qrscanner as QRScanner)
      .then(async function (qrscanner) {
        //torch.active = !!qrscanner.isFlashOn();
        torch.enabled = !!(await qrscanner.hasFlash());
        if (torch.active) await qrscanner.turnFlashOn();
        torch.active = qrscanner.isFlashOn();
        return qrscanner;
      })
      .catch(function (error) {
        logger("error=", error);
        fallback = true;
      })
      .finally(() => {
        loading = false;
      });
  }

  function stopStream() {
    qrscanner?.stop();
  }

  function onVisibilityChange() {
    if (document.hidden) {
      stopStream();
    } else {
      startStream();
    }
  }

  onMount(() => {
    // this is handled by qrscanner
    //document.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      //document.removeEventListener("visibilitychange", onVisibilityChange);
      stopStream();
      qrscanner?.destroy();
      qrscanner = null;
    };
  });

  const onchange: ChangeEventHandler<HTMLInputElement> = function onchange(e) {
    var blob = e.currentTarget.files?.[0];
    if (!blob) return;

    onframe?.(blob);

    if (barcode) {
      QRScanner.scanImage(blob, {
        returnDetailedScanResult: true,
        //qrEngine: qrengine,
      })
        .then(function (result) {
          logger("result=", result);
          onbarcode?.(result.data, blob);
        })
        .catch(function (error) {
          logger("error=", error);
        });
    }
  };

  const onclick: MouseEventHandler<HTMLButtonElement> = function onclick(e) {
    // if (cover) {
    //   return;
    // }
    // const imgData = await getFrame(videoEl, null);
    // const blob = await imageDataToBlob(imgData);
    // fireFrame({ blob });
    // if (barcode) {
    //   const result = await getBarcode(imgData);
    //   fireBarcode({ barcode: result, blob });
    // }
  };

  const onresize = debounce(() => {
    //logger("resizing video", videoEl, qrscanner);
    //qrscanner?._updateOverlay();
    if (qrscanner)
      qrscanner._scanRegion = qrscanner._calculateScanRegion(qrscanner.$video);
    //logger("resized video", videoEl, qrscanner);
  });

  const ontorch: ChangeEventHandler<HTMLInputElement> = function ontorch(e) {
    //logger("torch=", e);
    qrscanner?.toggleFlash().then(() => {
      torch.active = !!qrscanner?.isFlashOn();
    });
    //torch.active = !!qrscanner?.isFlashOn()
  };

  // async function toggleTorch(e: Changeeven) {
  //   await qrscanner?.toggleFlash();
  //   torch.active = !!qrscanner?.isFlashOn();
  // }
</script>

<figure
  class="camera {className}"
  bind:this={figureEl}
  class:loading
  class:video={!fallback}
>
  {#if fallback}
    <input type="file" accept="image/*" capture="environment" {onchange} />
  {:else if !loading}
    <button type="button" {onclick}></button>
  {/if}

  <img src={cover || ""} alt="scanning preview" />

  {#if upload}
    <input type="file" accept="image/*" {onchange} />
  {/if}

  {#if torch.enabled}
    <input
      type="checkbox"
      name="torch"
      checked={torch.active}
      onchange={ontorch}
    />
  {/if}

  <video
    bind:this={videoEl}
    playsinline
    autoplay
    muted
    use:resize={onresize}
    {onresize}
  ></video>

  {#if !loading && !fallback}
    <figcaption>
      {@render children?.()}
    </figcaption>
  {/if}
</figure>
